
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "VenuesDataTable",
  props: {
    tableData: Object,
    activeTab: String
  },
  computed: {
    ...mapGetters("VenuesModule", ["myVenues", "isBeingAddedToMyVenues"])
  },
  methods: {
    openDeleteModal(venue) {
      this.$emit("openDeleteModal", venue);
    },
    openDeclineModal(venue) {
      this.$emit("openDeclineModal", venue);
    },
    openMakePublicModal(venue) {
      this.$emit("openMakePublicModal", venue);
    },
    openEditModal(venue) {
      this.$emit("openEditModal", venue);
    }
  }
});
