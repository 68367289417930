<template>
  <div class="card">
    <ViewVenueModal :venue="currentlyViewedVenue"></ViewVenueModal>
    <DeleteModal
      v-show="isThereAModalActive"
      @delete="deleteSelectedVenue"
      :currentlySelectedEntry="currentlySelectedVenue.name"
    ></DeleteModal>
    <VenueModal
      ref="createEditVenueModal"
      :title="modalSetup.title"
      @saveChanges="saveDataFromModal"
      :action="modalSetup.action"
    ></VenueModal>
    <SingleActionModal
      :setup="modalSetup"
      @actionButtonClicked="updateVenueStatus"
    >
    </SingleActionModal>
    <div class="card mb-5 mb-xl-8">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Venues</span>

          <span class="text-muted mt-1 fw-bold fs-7">List of venues</span>
        </h3>
        <div
          class="ms-auto card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a new venue"
        >
          <a
            href="#"
            class="btn btn-sm btn-success"
            @click="openAddNewModal"
            ><span class="svg-icon svg-icon-3"
              ><svg
                fill="none"
                viewBox="0 0 24 24"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="black"
                ></rect>
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="black"
                ></rect></svg
            ></span>
            Add new Venue
          </a>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="row mt-3 mb-5 justify-content-center">
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <SearchField
              :placeholder="'Search venues'"
              @changeSearch="updateSearchByTextInput"
            ></SearchField>
          </div>
          <div class="col-12 col-md-4">
            <CountriesDropdown
              :placeholder="'Select venue country'"
              @change="updateSearchByCountry"
            ></CountriesDropdown>
          </div>
        </div>
        <ul class="nav nav-tabs flex-row">
          <li class="nav-item flex-grow-1 flex-md-grow-0" v-if="!$can('can_view_new_venues', 'all')">
            <a
              @click.prevent="changeActiveTab('myVenues')"
              href=""
              class="nav-link"
              :class="activeTab == 'myVenues' ? 'active' : ''"
            >
              <span class="nav-icon"
                ><i
                  class="
                    fas fa-lg fa-plus-circle mx-3"
                ></i
              ></span>
              <span class="nav-text fs-3">My Venues</span>
            </a>
          </li>
          <li class="nav-item flex-grow-1 flex-md-grow-0 mb-4 mb-md-0">
            <a
              @click.prevent="changeActiveTab('active')"
              href=""
              class="nav-link"
              :class="activeTab == 'active' ? 'active' : ''"
            >
              <span class="nav-icon"
                ><i
                  class="
                    fas fa-lg fa-globe-europe mx-3"
                ></i
              ></span>
              <span class="nav-text fs-3">Public venues</span>
            </a>
          </li>
          <li
            class="nav-item flex-grow-1 flex-md-grow-0"
            v-if="$can('can_view_new_venues', 'all')"
          >
            <a
              @click.prevent="changeActiveTab('new')"
              href=""
              class="nav-link"
              :class="activeTab == 'new' ? 'active' : ''"
            >
              <span class="nav-icon"
                ><i
                  class="
                    fas fa-lg fa-plus-circle mx-3"
                ></i
              ></span>
              <span class="nav-text fs-3">New Venues</span>
            </a>
          </li>
          
        </ul>
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <el-empty
          v-else-if="getData == ''"
          :description="
            'OOPS... Unfortunately no venues matching your filters were found'
          "
        ></el-empty>
        <VenuesDataTable
          v-else
          :tableData="getData"
          :activeTab="activeTab"
          @openDeleteModal="openDeleteModal"
          @openEditModal="openEditModal"
          @openDeclineModal="openDeclineModal"
          @openMakePublicModal="openMakePublicModal"
          @addToMyVenues="handleAddToMyVenues"
          @view="handleViewModal"
        ></VenuesDataTable>
        <Pagination
          v-show="pagination.lastPage != 1"
          :currentPage="pagination.currentPage"
          :totalPages="pagination.lastPage"
          @changeCurrentPage="changePage"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import VenuesDataTable from "@/components/venues/VenuesDataTable.vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import CountriesDropdown from "@/components/dropdown/CountriesDropdown.vue";
import SearchField from "@/components/general/SearchField.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import VenueModal from "@/components/venues/modal/VenueModal.vue";
import SingleActionModal from "@/components/general/SingleActionModal.vue";
import ViewVenueModal from "@/components/venues/modal/ViewVenueModal.vue"
 
export default defineComponent({
  name: "VenuesIndex",
  data() {
    return {
      currentlyViewedVenue: null,
      currentlySelectedVenue: {
        id: 1,
        name: ""
      },
      activeTab: "active",
      pagination: {
        currentPage: 1,
        lastPage: 1
      },
      searchParams: {
        countryID: "",
        searchText: ""
      },
      isThereAModalActive: false,
      modalSetup: {
        action: "",
        title: ""
      },
      renderComponent: true
    };
  },
  components: {
    VenuesDataTable,
    LoadingSpinner,
    CountriesDropdown,
    SearchField,
    Pagination,
    DeleteModal,
    VenueModal,
    SingleActionModal,
    ViewVenueModal
  },
  methods: {
    ...mapActions("VenuesModule", [
      "fetchData",
      "changePageNumber",
      "setSearchParameters",
      "resetPageNumber",
      "setActiveTab",
      "destroyData",
      "resetState",
      "createNew",
      "approveVenue",
      "declineVenue",
      "addToMyVenues",
      "update",
      "fetchMyVenues"
    ]),
    changeActiveTab(value) {
      this.activeTab = value;
      this.setActiveTab(this.activeTab);
      this.resetPageNumber();
      this.fetchData();
    },
    handleViewModal(data) {
      this.currentlyViewedVenue = data;
    },
    changePage(pageNumber) {
      this.changePageNumber(pageNumber);
      this.fetchData();
    },
    updateSearchByCountry(value) {
      this.searchParams.countryID = value;
      this.setSearchParameters(this.searchParams);
      this.resetPageNumber();
      this.fetchData();
    },
    updateSearchByTextInput(value) {
      this.searchParams.searchText = value;
      this.setSearchParameters(this.searchParams);
      this.resetPageNumber();
      this.fetchData();
    },
    openDeleteModal(venue) {
      this.currentlySelectedVenue = venue;
      this.isThereAModalActive = true;
    },
    deleteSelectedVenue() {
      this.destroyData(this.currentlySelectedVenue.id);
      this.currentlySelectedVenue.id = 1;
      this.isThereAModalActive = true;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          payload: dataFromModal.payload,
          id: this.currentlySelectedVenue.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
      this.forceRerender();
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    openEditModal(venue) {
      this.$refs.createEditVenueModal.toggleModal(true, venue);
    },
    openAddNewModal() {
      this.modalSetup.action = "addnew";
      this.modalSetup.title = "Create new venue";
      this.$refs.createEditVenueModal.toggleModal(true, null);
    },
    openDeclineModal(venue) {
      this.currentlySelectedVenue = venue;
      const SetupModal = {
        modalText: "Do you want to decline " + venue.full_name,
        buttonText: "Decline",
        buttonClass: "btn btn-danger",
        action: "Decline"
      };
      this.modalSetup = SetupModal;
    },
    handleAddToMyVenues(venue) {
      this.addToMyVenues(venue);
    },
    updateVenueStatus(actionType) {
      if (actionType == "Decline") {
        this.declineVenue(this.currentlySelectedVenue.id);
      } else {
        this.approveVenue(this.currentlySelectedVenue.id);
      }
    },
    openMakePublicModal(venue) {
      this.currentlySelectedVenue = venue;
      const SetupModal = {
        modalText: "Make " + venue.full_name + " public",
        buttonText: "Approve",
        buttonClass: "btn btn-success",
        action: "Approve"
      };
      this.modalSetup = SetupModal;
    },
  },
  computed: {
    ...mapGetters("VenuesModule", ["getData", "loading", "getPaginationData"])
  },
  mounted() {
    if (this.activeTab == "active" && !this.$can("can_view_new_venues")) {
      this.changeActiveTab("myVenues");
      this.fetchMyVenues();
    }
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  },
  watch: {
    activeTab() {
      if (this.activeTab == "active" && !this.$can("can_view_new_venues")) this.fetchMyVenues();
    }
  }
});
</script>
