<template>
  <!--begin::VenuesList-->
  <div class="row gy-5 g-xl-8">
    <VenuesIndex widget-classes="card-xxl-stretch mb-5 mb-xl-8"></VenuesIndex>
  </div>
  <!--end::VenuesList-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import VenuesIndex from "@/components/venues/VenuesIndex.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "VenuesView",
  components: {
    VenuesIndex
  },
  mounted() {
    setCurrentPageTitle("Venues");
  }
});
</script>
