
/* eslint-disable @typescript-eslint/camelcase */
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import CountriesDropdown from "@/components/dropdown/VeeCountriesDropdown.vue";
import ClientsDropdown from "@/components/dropdown/ClientDropdown.vue";
import { Modal } from "bootstrap";
import { useAbility } from "@casl/vue";
import { useStore } from "vuex";

export interface VenueData {
  id: number;
  name: string;
  entrance: string;
  country_id: number;
  city: string;
  address: string;
  zip: string;
  province: string;
  notes: string;
  company_id: number;
}

export default defineComponent({
  components: {
    ErrorMessage,
    Field,
    Form,
    CountriesDropdown,
    ClientsDropdown
  },
  name: "VenueModal",
  props: {
    currentlySelectedVenue: Object,
    action: String,
    title: String
  },
  setup: function() {
    const modal = ref<Modal | null>(null);
    const element = ref<HTMLElement | null>(null);
    const client = ref<number | null>(null);
    const canSelectClient = ref<boolean>(true);
    const formComponent = ref();
    const loading = ref<boolean>(false);
    const store = useStore();
    const casl = useAbility();
    const isEditing = ref<boolean>(false);
    const editedVenueId = ref<number | null>(null);

    onBeforeMount(() => {
      canSelectClient.value = !casl.can("view_only_own_company_jobs", "all");
    });

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required()
        .label("Name"),
      city: Yup.string()
        .required()
        .label("City"),
      country: Yup.string()
        .required()
        .label("Country"),
      address: Yup.string()
        .required()
        .label("Address")
    });

    function resetData(): void {
      client.value = null;
      isEditing.value = false;
      editedVenueId.value = null;
      formComponent.value.resetForm();
    }

    onMounted((): void => {
      element.value = document.getElementById("createEditVenueModal");
      if (element.value) {
        modal.value = new Modal(element.value);
        element.value?.addEventListener("hidden.bs.modal", resetData);
      }
    });

    onUnmounted((): void => {
      element.value?.removeEventListener("hidden.bs.modal", resetData);
    });

    function toggleModal(toggle: boolean, data: null | VenueData) {
      if (modal.value) {
        if (toggle) {
          modal.value?.show();
          if (data) {
            isEditing.value = true;
            editedVenueId.value = data.id;
            client.value = data.company_id;
            formComponent.value.setFieldValue("name", data.name);
            formComponent.value.setFieldValue("city", data.city);
            formComponent.value.setFieldValue("entrance", data.entrance);
            formComponent.value.setFieldValue("address", data.address);
            formComponent.value.setFieldValue("country", data.country_id);
            formComponent.value.setFieldValue("zip", data.zip);
            formComponent.value.setFieldValue("province", data.province);
            formComponent.value.setFieldValue("notes", data.notes);
          }
        } else {
          modal.value?.hide();
        }
      }
    }

    function handleSave(data): void {
      setLoading(true);
      if (!isEditing.value) {
        const payload = canSelectClient.value
          ? { ...data, company_id: client.value }
          : data;
        store
          .dispatch("VenuesModule/createNew", payload)
          .then(() => {
            toggleModal(false, null);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        store
          .dispatch("VenuesModule/update", { ...data, id: editedVenueId.value })
          .then(() => {
            toggleModal(false, null);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }

    return {
      validationSchema,
      canSelectClient,
      formComponent,
      toggleModal,
      handleSave,
      isEditing,
      loading,
      client
    };
  }
});
